
import { defineComponent, ref, watch, onMounted, Ref } from "vue";

import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import moment from "moment";
import useEmitter from "@/composables/Emmiter";
import AuthService from "@/services/AuthService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/services/ApiService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import FilterBase from "@/layout/header/partials/filters/FilterBase.vue";
import Api from "@/services/Api";

interface StoreFilter {
  grupo: any[];
  loja: any[];
  data: any[];
  regiao: any[];
}

export default defineComponent({
  name: "filtro-dashboard",
  components: {
    FilterBase,
    ElConfigProvider,
  },

  setup(props, { emit }) {
    const store = useStore();
    const emitter = useEmitter();

    const activeModal = ref(false);
    const closeModal = ref(false);
    const storeGroups: Ref<any[]> = ref([]);
    const lojas: Ref<any[]> = ref([]);
    const regioes: Ref<any[]> = ref([]);

    const storeFilter: Ref<StoreFilter> = ref({ grupo: [], loja: [], data: [], regiao: [] });

    async function getStoreGroups() {
      const { data } = await Api.get("getAllStoreGroup");
      storeGroups.value = data.grupos;
    }

    async function getLojas(storeFilter) {
      // if (storeFilter.value.grupo.length == 0) {
      //   lojas.value = [];
      //   return null;
      // }
      const res = await Api.get("getAllStores", { idGrupo: storeFilter.value.grupo });
      lojas.value = res.data.lojas;
    }

    async function getRegioes() {
      const { data } = await Api.get("getRegioes");
      regioes.value = data.regioes;
    }

    async function enviarEmit() {
      await emitter.emit("filtro-dashboard", {
        grupoEloja: storeFilter.value,
      });
      closeModal.value = !closeModal.value;
    }

    emitter.on("Dashboard", async params => {
      storeFilter.value.data = params.dataSelecionada;
    });

    watch(
      () => storeFilter.value.grupo,
      () => {
        getLojas(storeFilter);
      }
    );

    onMounted(() => {
      getStoreGroups();
      getLojas(storeFilter);
      getRegioes();
    });

    return { storeFilter, storeGroups, lojas, enviarEmit, closeModal, regioes };
  },
});
